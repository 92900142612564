import React from 'react'
import { Title } from '../../components/UI/Title'
import { Subtitle } from '../../components/UI/Subtitle'

export const SPDILawContent = () => {
  return (
    <div>

      <article tw="mt-10 space-y-6">
        <Title>Background</Title>
        <p>
          10XTS was founded in 2017 in the peak of the blockchain era of pure 
          hype, when thousands of projects were flooding the market. Billions 
          of dollars poured into the sector within a short period of time.
        </p>
        <p>
          In the years following, the overwhelming majority of projects have 
          proven to be 1) outright scams; 2) unregistered securities offerings 
          and/or violations in some global jurisdiction; or, 3) just a stupid 
          idea that didn't actually solve any real-world problem simply 
          because of the existence of a "blockchain" in the stack somewhere.
        </p>
        <p>
          At the same time, it was this confluence that drove 10XTS to 
          recognize the merits to the proper architecture and application of 
          the technology to bring forward the envisioned efficiency, 
          transparency, automation, trust, etc.… as prophesied by the 
          blockchain futurists and gurus in the earliest of stages of the 
          Gartner Hype Cycle.
        </p>
        <p>
          With a significant background in LegalTech, RegTech, and compliance 
          prior to the "blockchain revolution," the founding team of 10XTS 
          dedicated themselves to bridging the gap between the capability of 
          the tech and the jurisdictional regulations that will never 
          disappear simply because some form of technology CAN enable something.
        </p>
        <p>
          Blockchain technology has an immense potential to bring global 
          benefit to capital and investing. However, the vector of approach in 
          getting there has been convoluted and tainted by such fantasies, and 
          subsequent regulatory idiosyncrasies and fraud. The stark reality is, 
          like any other significant technology shift, there exists many 
          generations of legacy incumbents who have already functioned within 
          the regulated framework long before the technological shift.
        </p>
        <p>
          While there has been a lot of clamoring to the contrary by the crypto 
          crowd, aside from an operational tweaking standpoint, securities law 
          is pretty good - and clear. The United States capital marketplace is 
          considered the pole position globally for good reason.
        </p>
        <p>
          Part of this market are… get this… banks and custodians. They already 
          provide electronic forms for custody of securities and have for 
          decades. Brokers and marketplaces trade and transact these electronic 
          forms of securities today. They exchange information with transfer 
          agents and administrative servicing companies. Everyone reports in 
          some prescribed manner (xBRL & EDGAR for example).
        </p>
      </article>

      <article tw="mt-10 space-y-6">
        <Subtitle>Inefficient Governance, Risk, and Compliance Records</Subtitle>
        <p>
          The problem and risk in using the status quo system are the inherent 
          lags and fraudulent vectors created from inefficient handling of records 
          and data. This antiquated system forces - longer cycle times for 
          transactions & processing, creating inefficiencies that lead to increased 
          costs for investors
        </p>
        <p>
          10XTS recognized this gap, and also saw the opportunity to leverage new 
          technologies to architect a clean, scalable solution to achieve 
          compliance while creating a more efficient market.
        </p>
        <p>
          Distributed Ledger Technology, itself, has been battle tested for years. 
          It has created incredible efficiency and speed, new product 
          methodologies, trading strategies, and compliant liquidity strategies. 
          While crypto has been the center of attention lately, just hasn't been 
          proven to achieve these goals in a regulatory-compliant way. The rush to 
          build ran afoul of the reality of the actual rule of law.
        </p>
        <p>
          So what's the gap?
        </p>
        <p>
          It's easy to move a crypto token from one wallet to another. It's not 
          as easy to make all the extended governance, risk, and compliance 
          records metadata as equally immutable, secure, provable, and efficient.
        </p>
        <p>
          And that was the catalyst for 10XTS to pursue the goal of democratizing 
          access to capital markets and wealth-producing assets for more people.
        </p>
        <p>
          On one hand, there are similar market goals to some of the cryptocurrency 
          projects, however, they represent a radical departure from the 
          recognition of the actual law and the recognition for the need of those 
          laws. Customer protection being at the top of the list, regulation and 
          compliance enforcement are generally the result of nefarious actors who 
          caused the need for such rules.
        </p>
        <p>
          In the regulatory "pecking order", aside from the government itself, 
          there's no more regulated institution than a chartered bank. 
        </p>
        <p>
          At their core, safety and soundness to protect the American people is 
          the resounding, common philosophy of all that which chartered 
          institutions do. Risk mitigation, process control, and governance is at 
          the heart of everything.
        </p>
      </article>

      <article tw="mt-10 space-y-6">
        <Subtitle>The Need for Qualified Custody</Subtitle>
        <p>
          We recognized the evolution of capital markets was impeded by the 
          absence of institutions - capable of supporting a regulatory-compliant 
          implementation of the technology.  This "last mile" is the final 
          capability required to enable a more efficient and compliant market. 
        </p>
        <p>
          Banking core processors are notoriously slow to innovate, and the 
          market was over-run with the "banking your cryptocurrency" discussions. 
          Looking past the cryptocurrency custody noise of the moment, we saw 
          the need and opportunity to focus on the qualified custody of traditional 
          securities that had been enumerated in a controllable electronic record 
          vs. the simple institutional custody of a customer's cryptocurrency.
        </p>
        <p>
          During 2018, 10XTS established the concept of a bank project to support 
          the market demand, and, subsequently registered the domain 
          commerciumbank.com. The idea of 'commercium' and its Latin roots from 
          Roman citizenship and the right to conduct commerce made a great 
          reference to what would eventually become our foray into the chartered 
          banking side.
        </p>
      </article>

      <article tw="mt-10 space-y-6">
        <Subtitle>Wyoming Special Purpose Depository Institutions (SPDI)</Subtitle>
        <p>
          In 2019, the State of Wyoming blazed the trail when it enacted SF 125 
          defining a regulatory framework for digital assets, and HB 74, which 
          authorized a new form of financial institution called a "special purpose 
          depository institution."
        </p>
        <p>
          And then later the same year, the SEC/FINRA released a joint staff 
          statement pertaining to the broker- dealer custody of digital securities, 
          which bolstered support for Wyoming's regulatory approach to market 
          infrastructure and custody operations.
        </p>
        <p>
          The custodial requirements under Rule 15c3-3 under the Securities Exchange 
          Act of 1934, is commonly referred to as the "Customer Protection Rule," 
          which applies to entities that buy, sell, or are otherwise involved in 
          securities transactions, including transactions involving digital 
          instruments that are both deemed to be a security AND a digital 
          instrument that is specifically used to account for something that is 
          indisputably a traditional security instrument.
        </p>
        <p>
          The SEC/FINRA joint staff statement specifically calls out the custodial 
          requirements under the Customer Protection Rule, which exists to protect 
          customers' securities held by broker-dealers should that broker fail. The 
          Customer Protection Rule requires brokers to separate customer assets from 
          the firm's own investment accounts.
        </p>
        <p>
          Under the Customer Protection Rule, broker-dealers are required to maintain 
          physical possession or control over a customers' securities that are fully 
          paid and excess margin securities, or they must maintain them free of any 
          encumbrances or liens at a "good control location". The good control 
          location requirement permits broker-dealers to use third-party custodians, 
          such as banks and transfer agents, to custody their brokerage client 
          securities.
        </p>
        <p>
          While the great advancements by Wyoming quickly got overshadowed by the 
          cryptocurrency conversation, there were a few interesting advancements 
          that significantly favors consumers with respect to qualified custody 
          and banking.
        </p>
        <p>
          Do you own your security if it's held on the balance sheet of your broker 
          subordinate to their own interests with any liability ahead of your 
          position?
        </p>
        <p>
          While "custody" exists today in securities markets, the bailment aspect 
          becomes highly convoluted with the indirect ownership rendering the 
          bailment ineffective. If your broker dealer fails, it may not necessarily 
          return your shares of AAPL, GOOGL, or AMZN to you because those shares 
          are really a pro rata share of your broker's street name account.
        </p>
        <p>
          Yes, we're talking about the potential of an FTX kind of event happening 
          with your traditional shares of AAPL in your traditional brokerage 
          account.
        </p>
        <p>
          Let that sink in.
        </p>
        <p>
          You do not own a single share of stock in the current market. It's not in 
          your name.
        </p>
        <p>
          The brilliance of Wyoming's approach mitigates this risk - albeit along 
          with the dismantling of the mandated intermediary system.
        </p>
        <p>
          When SF 125 was signed into law, it made the current construct of 
          indirect ownership "optional" while preserving owners of digital 
          securities to retain their direct property rights under the Uniform 
          Commercial Code without jeopardizing protection for lenders. SF 125 
          aligned digital assets with the existing law while extending a lot of 
          benefits to all parties engaged in transactions.
        </p>
        <p>
          It also eliminated the indirect ownership regime imposed by UCC Article 8 
          because the ledger confirms in almost real time the seller of an asset has 
          actual real ownership. Manual verification by traditional process via 
          intermediaries can be facilitated by the trust enablement of the 
          mathematical proofing of a distributed ledger or blockchain.
        </p>
        <p>
          In effect, no securities custodian in existence can provide what an SPDI 
          can for assets, an effective bailment while preserving direct ownership!
        </p>
        <p>
          The other, very interesting and unique aspect of SF 125 is that with 
          respect to digital assets and securities that have not been perfected by 
          the control, any trailing encumbrances of an asset purchased by any 
          third party without knowledge of the existence of the prior adverse 
          claims are removed. This is a massive risk mitigation feature to 
          facilitate liquidity for assets.
        </p>
        <p>
          This means that if something was missed in diligence by a buyer prior to 
          acquiring an asset, they can be confident at any time those assets are 
          free and clear. Clean, no trailing liabilities. Your purchased asset 
          remains your asset!
        </p>
      </article>

      <article tw="mt-10 space-y-6">
        <Subtitle>Uniform Law Commission and Uniform Commercial Code (UCC)</Subtitle>
        <p>
          At the time in 2019, the Uniform Law Commission was pushing their 
          Supplemental Act, which extended the existing Section 8 of the UCC. 
          The State of Wyoming rightly rejected the overtures by the Uniform 
          Law Commission to embrace their approach. The state held steady with 
          their approach to making indirect ownership of a digital asset, along 
          with all the associated risks of indirect ownership, an option - not 
          a requirement.
        </p>
        <p>
          Since 2019, the Uniform Law Commission has moved away from rolling 
          everything under Section 8 towards the Wyoming approach, and in 2022 
          introduced revised UCC language that creates an entirely new Section 
          12 defining the bailment and perfection by control of an electronic 
          record.
        </p>
        <p>
          By the way, that's the legal nomenclature that will be enshrined into 
          code: controllable electronic record (CER), which may or may not be a 
          "token" since technology could change in the future.
        </p>
        <p>
          Perfection by control of an electronic record is a fancy way for saying 
          that an institution controls the digital unit of account, like a token, 
          within an institutional account wallet wherein only the institution has 
          ability to conduct any authorized operation or activity against that 
          data record, in this case, by way of "private keys" to the customer's 
          account. These keys give the client complete ownership of the record, 
          which is not part of the institution's balance sheet, nor co-mingled 
          with other assets and liabilities.
        </p>
        <p>
          The new Section 12 UCC revisions are now on the docket in 16 states for 
          further adoption. Wyoming's foresight and leadership in this area has 
          given them and their SPDI's pole position for banks to realize these 
          new advances.
        </p>
      </article>

      <article tw="mt-10 space-y-6">
        <Subtitle>Commercium Bank and 10XTS</Subtitle>
        <p>
          Aside from the philosophical alignments with serving the market and 
          clients while bringing new efficiencies through the tech, Wyoming and 
          the SPDI banking legislation has been very near and dear to 10XTS.
        </p>
        <p>
          When the Wyoming laws were signed, the 10XTS team determined that it 
          would pursue a Wyoming SPDI application charter and began formal 
          incubation of the project. 10XTS incubated and launched what became 
          the fourth SPDI charter approval - Commercium Bank.
        </p>
        <p>
          Together we managed to create a highly experienced banking team with 
          over 100 years of banking and Compliance experience who agreed to be 
          both Founders and part of the core proposed executive team.  With the 
          core team established, it was off to the races to file an application 
          for the de novo charter.
        </p>
        <p>
          While COVID disrupted early 2020, by the end of second quarter of that 
          year, we'd fully fleshed out the core thesis and developed a disciplined 
          approach for our application of a Wyoming SPDI charter. By the end of 
          2020, we had developed an application totaling nearly 1,000 pages that 
          was subsequently approved by the Wyoming Banking Division to proceed 
          towards a charter hearing set for August of 2021.
        </p>
        <p>
          Commercium Bank's charter was subsequently approved, and the bank team 
          began working on funding the operations required to be approved to 
          operate as a de novo charter.
        </p>
      </article>

      <article tw="mt-10 space-y-6">
        <Subtitle>Advancing Qualified Custody of Digital Securities</Subtitle>
        <p>
          We know you've heard very little about Commercium Bank, that's because 
          it has intentionally focused solely on building a regulatory compliant 
          Bank. Commercium Bank was never conceived around cryptocurrency, 
          providing banking legitimacy to cryptocurrency assets, stablecoins, or 
          any other crypto market focused noise.  Our mission is to provide 
          clients with a more compliant, efficient, and secure solution to 
          ownership of their assets.
        </p>
        <p>
          The thesis of Commercium Bank has always been about providing the 
          highest form of ownership assurance that exceeds compliance requirements 
          for institutions and individuals participating in the securities 
          markets. In fact, Commercium's solution provides better security for 
          than the present market-wide system of indirect ownership.  Our service 
          does not depend on intermediary sub-ledger accounting which as described 
          earlier, is a risk to every security holder.
        </p>
        <p>
          The real SPDI disruption was always about giving ownership of your 
          assets as an investor back to you.  We believe you should have a secure, 
          simple, and safe path to liquidity that operates WITHIN the law and the 
          Federal Reserve system.
        </p>
        <p>
          This makes good on providing the transparency, security, and 
          opportunities afforded by the technology - with a good old-fashioned 
          dose of banking safety and soundness attached.
        </p>
        <p>
          Corey Reason, CEO of Commercium Bank<br />
          Michael Hiles, Founder & CEO of 10XTS<br />
          February 28, 2023
        </p>
      </article>
    </div>
  )
}
